<template>
  <div class="page" v-title data-title="个人记录">
    <div class="weui-tab">
      <div class="weui-navbar">
        <div
          v-on:click="changeTab(1)"
          class="weui-navbar__item"
          v-bind:class="{ 'weui-bar__item_on': tabIdx === 1 }"
        >
          <span v-if="tabIdx === 1" style="color: #0066FF;">周</span>
          <span v-else>周</span>
        </div>
        <div
          v-on:click="changeTab(2)"
          class="weui-navbar__item"
          v-bind:class="{ 'weui-bar__item_on': tabIdx === 2 }"
        >
          <span v-if="tabIdx === 2" style="color: #0066FF;">月</span>
          <span v-else>月</span>
        </div>
        <div
          v-on:click="changeTab(3)"
          class="weui-navbar__item"
          v-bind:class="{ 'weui-bar__item_on': tabIdx === 3 }"
        >
          <span v-if="tabIdx === 3" style="color: #0066FF;">季</span>
          <span v-else>季</span>
        </div>
        <div
          v-on:click="changeTab(4)"
          class="weui-navbar__item"
          v-bind:class="{ 'weui-bar__item_on': tabIdx === 4 }"
        >
          <span v-if="tabIdx === 4" style="color: #0066FF;">年</span>
          <span v-else>年</span>
        </div>
      </div>
    </div>
    <scroller :on-refresh="refresh" :on-infinite="infinite" ref="my_scroller">
      <div v-for="(item, i) in list" :key="i">
        <div class="weui-cells__title" style="font-size: 16px;">
          {{ item.time }}
        </div>
        <div
          class="weui-cells"
          style="margin: auto 10px; border-radius:5%; font-size: 16px;"
        >
          <div class="weui-cell" style="padding: 12px;">
            <div
              style="width: 8px; height: 8px; border-radius: 50%; background-color: #ECEFF1; display: inline-block;"
            ></div>
            <div class="weui-cell__bd" style="margin-left: 10px;">
              <p>累计时间</p>
            </div>
            <div class="weui-cell__ft">{{ item.ct }}H</div>
          </div>
          <div class="weui-cell" style="padding: 12px;">
            <div
              style="width: 8px; height: 8px; border-radius: 50%; background-color: #ECEFF1; display: inline-block;"
            ></div>
            <div class="weui-cell__bd" style="margin-left: 10px;">
              <p>流量大小</p>
            </div>
            <div class="weui-cell__ft">{{ item.ll }}L/min</div>
          </div>
          <div class="weui-cell" style="padding: 12px;">
            <div
              style="width: 8px; height: 8px; border-radius: 50%; background-color: #ECEFF1; display: inline-block;"
            ></div>
            <div class="weui-cell__bd" style="margin-left: 10px;">
              <p>氧浓度显示</p>
            </div>
            <div class="weui-cell__ft">{{ item.ynd }}%</div>
          </div>
          <div class="weui-cell" style="padding: 12px;">
            <div
              style="width: 8px; height: 8px; border-radius: 50%; background-color: #ECEFF1; display: inline-block;"
            ></div>
            <div class="weui-cell__bd" style="margin-left: 10px;">
              <p>血氧饱和度</p>
            </div>
            <div class="weui-cell__ft">{{ item.xy }}%</div>
          </div>
        </div>
      </div>
    </scroller>
  </div>
</template>

<script>
import http from "@/utils/http";

const tab_arr = ["week", "month", "season", "year"];
export default {
  name: "Awgels_Record",
  data() {
    return {
      deviceNo: this.$route.params.deviceNo,
      tabIdx: 1,
      list: [],
      pageNo: 0,
      pageSize: 5,
      date: new Date()
    };
  },
  methods: {
    changeTab(idx) {
      // 回到顶部
      this.$refs.my_scroller.scrollTo(0, 0, false);
      this.tabIdx = idx;
      this.refresh();
    },
    // 下拉加载最新数据
    refresh() {
      // 重置页数
      this.pageNo = 1;
      this.fetchData();
      // 关闭加载动画
      this.$refs.my_scroller.finishPullToRefresh();
    },
    // 上拉加载更多数据
    infinite(done) {
      if (this.pageNo < 50) {
        this.pageNo++;
        this.fetchData(done);
      } else {
        this.$refs.my_scroller.finishInfinite(true);
      }
    },
    fetchData(done) {
      http
        .instance({
          url: "/api/data/" + tab_arr[this.tabIdx - 1],
          method: "get",
          params: {
            deviceNo: this.deviceNo,
            date: this.date.format("yyyy-MM-dd"),
            pageNo: this.pageNo,
            pageSize: this.pageSize
          }
        })
        .then(res => {
          if (this.pageNo === 1) {
            this.list = res.data;
          } else {
            this.list = this.list.concat(res.data);
          }
          if (done) {
            done(); // 继续下次加载操作
          }
        })
        .catch(err => {
          console.log("err: " + JSON.stringify(err));
          // 加载异常，关闭加载动画
          //this.$refs.my_scroller.finishInfinite(true);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.weui-navbar {
  background-color: #eceff1;
}
.weui-navbar:after {
  border-bottom: none;
}
.weui-navbar__item:after {
  border-right: none;
}
.weui-navbar__item.weui-bar__item_on {
  background-color: #ffffff;
}
._v-container {
  top: 60px !important;
  height: calc(100% - 60px) !important;
}
</style>
